module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/Favicon_512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"02233155d797502193ec57c0e46835dc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"5","matomoUrl":"https://stats.denkundmach.com/","siteUrl":"https://denkundmach.com","requireConsent":false,"requireCookieConsent":false,"disableCookies":true,"dev":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 1px)","sm":"(max-width: 768px)","md":"(min-width: 769px) and (max-width:992px)","l":"(min-width: 993px)","xl":"(min-width: 1000px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","generateDefaultLanguagePage":false,"redirect":false,"siteUrl":"https://denkundmach.de/","i18nextOptions":{"interpolation":{"escapeValue":false},"react":{"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i","span"]},"keySeparator":false,"nsSeparator":false,"defaultNS":"translation"},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
