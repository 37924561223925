exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-atmospheric-data-js": () => import("./../../../src/pages/projects/AtmosphericData.js" /* webpackChunkName: "component---src-pages-projects-atmospheric-data-js" */),
  "component---src-pages-projects-augmented-livingspaces-js": () => import("./../../../src/pages/projects/augmented-livingspaces.js" /* webpackChunkName: "component---src-pages-projects-augmented-livingspaces-js" */),
  "component---src-pages-projects-bauhaus-js": () => import("./../../../src/pages/projects/Bauhaus.js" /* webpackChunkName: "component---src-pages-projects-bauhaus-js" */),
  "component---src-pages-projects-blick-punkt-moritzburg-js": () => import("./../../../src/pages/projects/BlickPunkt-Moritzburg.js" /* webpackChunkName: "component---src-pages-projects-blick-punkt-moritzburg-js" */),
  "component---src-pages-projects-bunt-ar-js": () => import("./../../../src/pages/projects/BuntAR.js" /* webpackChunkName: "component---src-pages-projects-bunt-ar-js" */),
  "component---src-pages-projects-bunt-vr-js": () => import("./../../../src/pages/projects/BuntVR.js" /* webpackChunkName: "component---src-pages-projects-bunt-vr-js" */),
  "component---src-pages-projects-cat-tree-configurator-js": () => import("./../../../src/pages/projects/CatTreeConfigurator.js" /* webpackChunkName: "component---src-pages-projects-cat-tree-configurator-js" */),
  "component---src-pages-projects-companion-orb-js": () => import("./../../../src/pages/projects/CompanionOrb.js" /* webpackChunkName: "component---src-pages-projects-companion-orb-js" */),
  "component---src-pages-projects-feininger-js": () => import("./../../../src/pages/projects/Feininger.js" /* webpackChunkName: "component---src-pages-projects-feininger-js" */),
  "component---src-pages-projects-haengende-gaerten-js": () => import("./../../../src/pages/projects/HaengendeGaerten.js" /* webpackChunkName: "component---src-pages-projects-haengende-gaerten-js" */),
  "component---src-pages-projects-immersives-erzaehlen-js": () => import("./../../../src/pages/projects/ImmersivesErzaehlen.js" /* webpackChunkName: "component---src-pages-projects-immersives-erzaehlen-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-moodpausch-js": () => import("./../../../src/pages/projects/Moodpausch.js" /* webpackChunkName: "component---src-pages-projects-moodpausch-js" */),
  "component---src-pages-projects-remmy-gallery-js": () => import("./../../../src/pages/projects/RemmyGallery.js" /* webpackChunkName: "component---src-pages-projects-remmy-gallery-js" */),
  "component---src-pages-projects-spiegelkabinett-js": () => import("./../../../src/pages/projects/Spiegelkabinett.js" /* webpackChunkName: "component---src-pages-projects-spiegelkabinett-js" */),
  "component---src-pages-projects-xr-mobility-js": () => import("./../../../src/pages/projects/XR-Mobility.js" /* webpackChunkName: "component---src-pages-projects-xr-mobility-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

